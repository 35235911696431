<template>
  <MitraPemasokForm mode="Tambah" module="Mitra Pemasok"> </MitraPemasokForm>
</template>

<script>
import MitraPemasokForm from './form';

const MitraPemasokAdd = {
  name: 'MitraPemasokAdd',
  components: { MitraPemasokForm },
};

export default MitraPemasokAdd;
</script>
